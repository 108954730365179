<template>
<div>
    <h1>Heading is: {{ heading }}</h1>
    <input v-model="noteText" class="input"/>
    <button @click="cancelNote"> Cancel </button>
    <button @click='setVal()'>Save</button>
</div>
</template>

<script>
export default {
    name: 'Note',
    data: function() {
        return {
            noteText: null
        };
    },
    props: {
        heading: String
    },
    methods: {
        setVal() {
            this.$emit('changeNote',this.noteText)
        },
        cancelNote() {
            this.$emit('cancel')
        }
    },
    created: function(){
    }
}
</script>

<style scoped>
h1 {
    font-size: 2em;
    text-align: center;
}
</style>