<template>
    <div id='communicationRow'>
        <CListGroupItem
            :to="{ name: 'communication', params: { communicationId: communicationId }}"
            class="flex-column align-items-start"
        >
        <CRow>
                <CCol class="text-truncate">
            <div class="d-flex w-100 justify-content-between" v-if="communicationType == 'Email'">
                <div>
                <a-icon type="mail" /> &nbsp; {{emailTo}}
                </div>
                <small>{{ createdDate | formatDateAgo }}</small>
            </div>
            <div v-if="communicationType == 'Email'">
                <strong>{{emailSubject}}</strong>
            </div>
            <div v-if="communicationType == 'Email' && emailBodyText">
                {{emailBodyText}}
            </div>
            <div v-if="communicationType == 'Email' && !emailBodyText && emailBodyHtml">
                {{emailBodyTextFromHtml()}}
\            </div>
            <div class="d-flex w-100 justify-content-between" v-if="communicationType == 'Phone'">
                <div>
                <a-icon type="phone" /> &nbsp; {{phone}}
                </div>
                <small>{{ createdDate | formatDateAgo }}</small>
            </div>
            <div id="communicationPhoneBodyDivId" v-if="communicationType != 'Email'">
            <p> {{ message }}</p>
            </div>
                </CCol>
        </CRow>
        </CListGroupItem>
</div>
</template>

<script>

export default {
    name: 'CommunicationRow',
    components: {
        
    },
    methods: {
        convertHtmlToText(html) {
            // Create a new div element
            console.log('convert to text: ' + html)
            var tempDivElement = document.createElement("div");

            // Set the HTML content with the given value
            tempDivElement.innerHTML = html;

            // Retrieve the text property of the element 
            let text = tempDivElement.textContent || tempDivElement.innerText || ""
            console.log('CONVERTED to: ' + text)
            return text.replace('null',' ');
        },
        emailBodyTextFromHtml() {
            return this.convertHtmlToText(this.emailBodyHtml)
        }
    },
    props: {
        communicationId: Number,
        communicationType: String,
        emailTo: String,
        emailSubject: String,
        email: String,
        emailBodyHtml: String,
        emailBodyText: String,
        communicationType: String,
        phone: String,
        message: String,
        createdDate: String,
    }
}

</script>


