<template>
    <div>
        <!-- <bubble-menu
        class="bubble-menu"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
        v-if="editor"
        >
            <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                Bold
            </button>
            <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                Italic
            </button>
            <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
                Strike
            </button>
        </bubble-menu>
        <floating-menu
            class="floating-menu"
            :tippy-options="{ duration: 100 }"
            :editor="editor"
            v-if="editor"
            >
            <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                H1
            </button>
            <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                H2
            </button>
            <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
                Bullet List
            </button>
        </floating-menu> -->

    <div v-if="editor">
            <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                h1
            </button>
            <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                h2
            </button>
             <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
                h3
            </button>
            <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
                <a-icon type="unordered-list" />
            </button>
            <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
                <a-icon type="ordered-list" />
            </button>
            <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                <a-icon type="bold" />
            </button>
            <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                <a-icon type="italic" />
            </button>
    </div>
        <editor-content  class="border-box" :editor="editor" ref="tiptapEditor" />
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

export default {
  components: {
    EditorContent
    // ,BubbleMenu,
    // FloatingMenu
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
    }
  },
  methods: {
    focusEditor() {
      console.log('TipTap: focusInput: start')
      // this.$refs.editorRef.$el.focus();
      this.editor.commands.focus('end')
      // if (this.editor) {
      //   console.log('TipTap: editor exists')
      // } else {
      //   console.log('TipTap: editor does not exist')
      // }
      // this.editor.blur()
      // console.log('TipTap: this.editor: ' + JSON.stringify(this.editor))
    },
    initEditor() {
      console.log('TipTap: initEditor start')
      this.editor = new Editor({
        extensions: [
          StarterKit,
        ],
        content: this.value,
        // autoFocus: true,
        onUpdate: () => {
          // HTML
          this.$emit('input', this.editor.getHTML())
          console.log('TipTap: initEditor: set focus...')
          // this.editor.blur()
          // this.editor.focus()

          // JSON
          // this.$emit('input', this.editor.getJSON())
        },
      })
      // this.editor.ref = 'editorRef'
      // console.log('TipTap: mounted: call focusEditor')
      // this.focusEditor();
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }

      console.log('TipTap WATCH: setContent')
      this.editor.commands.setContent(this.value, false)
    },
  },

  mounted() {
    this.initEditor()
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }
}
.email-editor {
    border: 1px solid #ccc!important;
    padding: 4px;
}
.border-box {
    border: 1px solid #ccc!important;
    padding: 4px;
    margin-bottom: 4px;
}

.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.floating-menu {
  display: flex;
  background-color: #0D0D0D10;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
</style>