<template>
    <div id='jobRow'>
        <CListGroupItem
            :to="{ name: 'note', params: { noteId: noteId }}"
            class="flex-column align-items-start"
        >
        <CRow>
            <CCol class="text-truncate">
            <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">Note {{noteType}}</h5>
            <small>{{ createdDate | formatDateAgo }}</small>
            </div>
            {{noteText}}
            </CCol>
        </CRow>
        </CListGroupItem>
</div>
</template>

<script>

export default {
    name: 'NoteRow',
    components: {
        
    },
    props: {
        noteId: Number,
        noteText: String,
        noteType: String,
        createdDate: String,
    }
}

</script>


<style>
.indicator {
    margin-right: 10px;
}

.list-item {
    padding: 10px;
}
</style>